<template>
  <footer class="text-center bg-light py-2">
    <div class="d-flex justify-content-around align-content-center">

      <div class="d-flex align-content-center align-items-center">
        <p-icon name="phone" class="me-2"/>
        <a href="tel:+79035502040">
          +7 (903) 550-20-40
        </a>
      </div>
      <div class="d-flex align-content-center align-items-center">
        <p-icon name="mail" class="me-2"/>
        <a href="mailto:info@pv-1.ru">
          info@pv-1.ru
        </a>
      </div>
      <div class="d-flex align-content-center align-items-center">
        <p-icon name="business" class="me-2"/>
        ООО «Спринтмастер»
      </div>
      <a
        href='https://play.google.com/store/apps/details?id=ru.pv_1.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
        target="_blank">
        <img src='/img/ru_badge_web_generic.png'
             style="width: 190px !important;"
             @load="setBgHeight"
             alt='Доступно в Google Play'>
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped>

</style>
